import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'
import { Title, Section, Text, Button } from '../../components/Core'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { breakpoints } from '../../utils'
import Link from '../../components/Link'

const ButtonStyle = styled(Button)`
@media (max-width: ${breakpoints.md}px) {
  width: 100%;
}
background-color: #124D95;
`

const TextStyle = styled(Text)`
@media (max-width: ${breakpoints.md}px) {
  font-size: 18px;
}
`

const PortfolioMgt = props => {
  const { t } = useTranslation('landing', { useSuspense: false });
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false

  return (
    <>
      {/* <!-- PortfolioMgt section --> */}
      <Section
        bg={userDarkMode ? '#191B20' : 'white'}
        className="position-relative"
      >
        <Container>
          <Row>
            <Col lg="6">
              <Title variant={"highlight"} style={{ color: userDarkMode ? '#FAFAFA' : '#003D8B' }}>{t('multipleExchs')}</Title>
              <Text
                variant="strong"
                color={userDarkMode ? '#FAFAFA' : 'darkShade'}
                mb={4}
              >
                {t('ruleAll')}
              </Text>
            </Col>
            <Col lg="6">
              <Text
                color={userDarkMode ? '#FAFAFA' : 'darkShade'}
                mb={4}
              >
                {t('streamline')}
              </Text>
              <ButtonStyle>
                <Link to={`/${props.lang}/portfolio-management`}>
                  <TextStyle color="#FAFAFA">{t('morePortfolio')} </TextStyle>
                </Link>
              </ButtonStyle>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  )
}

export default PortfolioMgt
